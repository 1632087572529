import { ComingSoonScreen } from "src/components/ComingSoonScreen/ComingSoonScreen";
import { CyclicalBodySection } from "./CyclicalBodySection/CyclicalBodySection";
import "./HomePage.scss";

import { LandingSection } from "./LandingSection/LandingSection";
import { PathOfCreationSection } from "./PathOfCreationSection/PathOfCreationSection";
import { AboutMeSection } from "./AboutMeSection/AboutMeSection";
import { WorkedWithSection } from "./WorkedWithSection/WorkedWithSection";
import { NewsLetterSection } from "./NewsLetterSection";

export const HomePage = () => {

    return (
        <>
            <div className="home-sections" >
                <LandingSection />
                <CyclicalBodySection />
                <PathOfCreationSection />
                <AboutMeSection />
                <WorkedWithSection />
                <NewsLetterSection />
            </div>

            <ComingSoonScreen />
        </>
    );
};
