export enum AppRoutes {
    "root" = "/",
    "V1" = "Version1",
    "V2" = "Version2",
    "V3" = "Version3",
    'about' = 'about',
    'services' = 'services',
    'library' = 'library',
    'society' = 'society',
    'programs' = 'programs',
    'nourishAndFlourishLetters' = 'nourishAndFlourishLetters',
    'contact' = 'contact',
}