import { animated, useTransition, SpringConfig } from "@react-spring/web";
import { exists } from "i18next";

export const FeedBackBox = ({ feedback, person, occupation, position }: { feedback: string; person: string; occupation: string; position: "left" | "right" }) => {

    const transitions = useTransition({ feedback, person, occupation }, {
        from: { opacity: 0, left: -30 },
        enter: { opacity: 1, left: 0 },
        delay: position === 'right' ? 550 : 0,
        config: { duration: 500 } as SpringConfig
    })



    const isTranslationKey = exists(feedback) || exists(person) || exists(occupation)
    const isEmpty = feedback === "" || person === "" || occupation === ""

    if (isTranslationKey || isEmpty)
        return <div className="feedback-section-part"></div>

    return transitions((style, item) => <animated.div key={feedback} className="feedback-section-part" style={{ ...style, width: "100%" }}>
        <div className="feedback-text-area">
            <img src="/img/quotesAndLines.png" alt="quotes" className="quotes-and-lines-img" />
            <p>{feedback}</p>
        </div>
        <div className="d-flex flex-column feedback-person-area">
            <div className="ms-auto font-italianno h2">{person}</div>
            <div className="ms-auto font-montserrat">{occupation}</div>
        </div>
    </animated.div>);
};
