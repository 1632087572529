import { FormEvent, useRef, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha'



export const NewsLetterSection = () => {
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');


    async function submitForm(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const captchaValue = recaptchaRef?.current?.getValue()


        await fetch(`${process.env.REACT_APP_BE_URL}/Home/TokenVerification`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Token: captchaValue })
        });

        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        } else {
            // make form submission
            alert('Form submission successful!')
        }
    }

    return (
        <div>
            <h1>Sign up for Newsletter</h1>
            <form onSubmit={submitForm}>
                <input
                    name="Email"
                    type={'email'}
                    value={email}
                    required
                    placeholder="joe@example.com"
                    onChange={(event) => setEmail(event.target.value)} />
                <input
                    name="Name"
                    type={'name'}
                    value={name}
                    required
                    placeholder="Joe"
                    onChange={(event) => setName(event.target.value)} />
                <button type="submit">Sign up</button>
                <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY as any} />
            </form>
        </div>
    );
};
