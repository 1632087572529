import { useEffect, useRef, useState } from "react";
import './HorizontalRouletteWithLetterFadeInAndOut.scss'

export const HorizontalRouletteWithLetterFadeInAndOut = ({ phrases }: { phrases: string[] }) => {
    const first = useRef<HTMLSpanElement | null>(null)
    const [index, setIndex] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            first.current?.classList.remove('fade-in-text')
            first.current?.classList.add('stop-animations')
            setTimeout(() => {
                setIndex(i => (i + 1) % phrases.length)
                first.current?.classList.remove('stop-animations')
                first.current?.classList.add('fade-in-text')

            }, 100);
        }, 7000);
        return () => clearInterval(timer);
    }, []);


    const getLettersWrappedInSpan = (letters: string) => {
        return (<span ref={first} className='fade-in-text'>
            {Array.from(letters).map((_, index) => <span key={index}>{_}</span>)}
        </span>)
    }
    return <>{getLettersWrappedInSpan(phrases[index])}</>
}