import { HorizontalRouletteWithLetterFadeInAndOut } from "src/components/Animations/HorizontalRouletteWithLetterFadeInAndOut/HorizontalRouletteWithLetterFadeInAndOut";
import { EllipseShapedImage } from "src/components/Images/EllipseShapedImage/EllipseShapedImage";
import image from "src/img/vivi-sofa-smiling.jpg";
import { ActionButtonSquared } from "src/components/buttons/ActionButtonSquared/ActionButtonSquared";
import { t } from "i18next";
import { MediumSlowFadeInWrapper } from "src/animation/medium-slow-fade-in-wrapper";

export const LandingSection = () => {
    const phrases = [t("holisticFemaleSelfdevelopment"), t("cycleAwareness"), t("yoga")];

    return (

        <div className="text-white bg-nude vh-100">
            <div className="d-flex flex-row my-auto mx-auto">
                <div className="d-flex flex-column me-4" style={{ width: "20vw", maxWidth: "400px", margin: "5rem 0 0 0" }}>
                    <EllipseShapedImage image={image} className="d-flex w-100" />
                </div>
                <div className="d-flex flex-column ms-4 my-auto">
                    <div className="d-flex justify-content-center" style={{ transform: "translateY(-50px)", height: "20px" }}>
                        <div className="text-nude font-montserrat">
                            <HorizontalRouletteWithLetterFadeInAndOut phrases={phrases} />
                        </div>
                    </div>
                    <MediumSlowFadeInWrapper className="d-flex flex-column">

                        <div className="font-italianno mx-auto text-center" style={{ whiteSpace: "pre", fontSize: "4em", lineHeight: "1" }}>
                            {t("home-page-main-text")}
                        </div>
                        <i className="font-playfair-display h3 mt-2 mx-auto text-center" style={{ whiteSpace: "pre", fontSize: "1em", lineHeight: "2" }}>
                            {t("home-page-call-to-action-description-text")}
                        </i>

                        <ActionButtonSquared text={t("home-page-let-the-creation-begin")} className="w-55 mx-auto" />
                    </MediumSlowFadeInWrapper>
                </div>
            </div>
        </div>

    );
};
