import { Menu } from "./components/Menu/Menu";
import { AppRouter } from "./AppRouter";
import { withTranslation } from "react-i18next";
import "./styling/App.scss";

function App() {
  return <div className="d-flex flex-column" style={{ height: '100vh' }}>
    <Menu />
    <AppRouter />
  </div >
}
export default withTranslation()(App);