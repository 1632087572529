import { EllipseShapedImage } from "src/components/Images/EllipseShapedImage/EllipseShapedImage";
import image from "src/img/vivi-chill-pose.png"
import './AboutMeSection.scss'
import { t } from "i18next";
import { EllipseShapedContainer } from "src/components/Images/EllipseShapedContainer/EllipseShapedContainer";
import { MediumSlowFadeInWrapper } from "src/animation/medium-slow-fade-in-wrapper";

export const AboutMeSection = () => {
    return (
        <div className="d-flex flex-row my-auto justify-content-center sheet-bg vh-100 px-5" style={{ minHeight: '800px' }}>
            <MediumSlowFadeInWrapper className="d-flex">
                <div className="w-60 d-flex flex-column my-auto text-brown py-5 text-justify">
                    <h1 className="font-playfair-display fw-bold text-uppercase"> {t('home-page.about-me.title')}</h1>
                    <h5><em>{t('home-page.about-me.subtitle')}</em></h5>
                    <p>{t('home-page.about-me.textblock-1')}</p>
                    <p>{t('home-page.about-me.textblock-2')}</p>
                    <p>{t('home-page.about-me.textblock-3')}</p>
                    <em className="font-italianno h3">{t('home-page.about-me.textblock-4')}</em>

                </div>
                <div className="w-40 flex-column d-flex my-auto">
                    <EllipseShapedImage image={image} className="about-me-image" />
                    <EllipseShapedContainer className="bg-nude behind-about-me-image" />
                </div>
            </MediumSlowFadeInWrapper>
        </div>
    )
};
