import { t } from "i18next";

export const ComingSoonScreen = () => (
    <div className="w-100 bg-gold d-flex coming-soon" style={{ height: '100vh' }}>
        <div className="d-flex h-100 pt-5 mt-5 text-center">
            <div className="d-flex flex-column text-white font-montserrat">
                <h2 className="px-5"> {t('page-will-be-ready-for-phone-soon-title')} </h2>
                <p className="px-5"> {t('page-will-be-ready-for-phone-soon-subtitle')} </p>
            </div>
        </div>
    </div>
)