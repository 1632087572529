import { Button } from "react-bootstrap";
import "./ActionButtonSquared.scss";

export const ActionButtonSquared = (props: { text: string; className?: string }) => {
  const positioningClasses = "mt-4 px-2 py-2 ";
  const layoutClasses = "rounded-1 border-2 border-light bg-transparent";
  const textClasses = "squared-button-text-style text-light";
  const propsClasses = props.className ?? "";

  return (
    <Button
      className={`${positioningClasses} ${layoutClasses} ${textClasses} ${propsClasses}`}
      href="/"
      children={<>{props.text}</>}
    />
  );
};
