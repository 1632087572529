import { useEffect, useState } from "react";
import { FeedBackBox } from "./FeedBackBox";
import { exists, t } from "i18next";

export const FeedBackRotator = () => {
    const [indexer, setIndexer] = useState(0)

    const getLeftIndex = () => 2 * indexer
    const getRightIndex = () => 2 * indexer + 1

    const numberOfIndices = 3 // TODO: calculate

    useEffect(() => {
        // create a interval and get the id
        const intervalId = setInterval(() => {
            let leftIndex = getLeftIndex()
            let rightIndex = getRightIndex()

            if (exists(`home-page.worked-with.feedback${leftIndex}`) || exists(`home-page.worked-with.feedback${rightIndex}`)) {
                setIndexer(indexer + 1)
            }
        }, 5000);

        // clear out the interval using the id when unmounting the component
        return () => clearInterval(intervalId);
    }, [indexer]);


    let leftIndex = getLeftIndex()
    let rightIndex = getRightIndex()


    if (!exists(`home-page.worked-with.feedback${leftIndex}`) && !exists(`home-page.worked-with.feedback${rightIndex}`))
        setIndexer(0)


    return (
        <div className="d-flex vh-100 flex-column feedback-wrapper">
            <div className="feedback-rotator">
                <FeedBackBox position="left" feedback={t(`home-page.worked-with.feedback${leftIndex}`)}
                    occupation={t(`home-page.worked-with.occupation${leftIndex}`)}
                    person={t(`home-page.worked-with.person${leftIndex}`)} />

                <FeedBackBox position="right" feedback={t(`home-page.worked-with.feedback${rightIndex}`)}
                    occupation={t(`home-page.worked-with.occupation${rightIndex}`)}
                    person={t(`home-page.worked-with.person${rightIndex}`)} />


            </div>
            <div className="stepper">
                {Array(numberOfIndices).fill(1).map((_, index) =>
                    <div key={`cirle${index}`} id={index === indexer ? 'active' : ''} onClick={() => setIndexer(index)} />
                )}
            </div>
        </div>
    );
};


