import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import { AppLayout } from "./AppLayout";

export const AppRouter = () => {
    return (
        <Routes>
            <Route element={<AppLayout />}>
                <Route index element={<HomePage />} />
            </Route>
        </Routes>
    );
};

