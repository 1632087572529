
import { useInView, animated } from '@react-spring/web';
import { mediumSlowAnimationConfig } from './config/medium-slow-animation';

export const MediumSlowFadeInWrapper = ({ children, className }: { children: JSX.Element | JSX.Element[], className: string }) => {
    const [ref, springs] = useInView(
        () => ({
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: mediumSlowAnimationConfig
        }),
        { rootMargin: '-40% 0%', }
    )

    return <animated.div ref={ref} className={className ?? ''} style={{ ...springs }}>
        {children}
    </animated.div>
}