import { t } from 'i18next';
import './CyclicalBodySection.scss';
import { MediumSlowFadeInWrapper } from 'src/animation/medium-slow-fade-in-wrapper';

export const CyclicalBodySection = () => {

    return (
        <>
            <div className="text-white navelLady-bg px-5 vh-100" style={{ minHeight: "900px" }} >
                <MediumSlowFadeInWrapper className='d-flex flex-column mx-auto my-auto text-justify'>
                    <div className="d-flex flex-row my-4 me-auto font-playfair-display h1 fw-bold">
                        {t('home-page-cyclical-body-title')}
                    </div>
                    <div className="d-flex flex-row my-4 me-auto font-playfair-display">
                        {t('home-page-cyclical-body-textblock-1')}
                    </div>
                    <div className="d-flex flex-row my-4 me-auto font-playfair-display">
                        {t('home-page-cyclical-body-textblock-2')}
                    </div>
                    <div className="d-flex flex-row my-4 me-auto font-playfair-display">
                        {t('home-page-cyclical-body-textblock-3')}
                    </div>
                    <em className="d-flex flex-row my-4 mx-auto font-italianno h1">
                        {t('home-page-cyclical-body-textblock-4')}
                    </em>
                    <div className="d-flex flex-row my-4 me-auto font-playfair-display">
                        {t('home-page-cyclical-body-textblock-5')}
                    </div>
                    <em className="d-flex flex-row my-4 mx-auto font-italianno h1 text-center">
                        {t('home-page-cyclical-body-textblock-6')}
                    </em>
                </MediumSlowFadeInWrapper>
            </div>
        </>)

}