import "./Menu.scss";
import image from "src/img/logo_vizszintes-logo-arany.svg";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { AppRoutes } from "src/AppRoutes";
import { changeLanguage, t } from "i18next";
import { Navbar, Container, Nav } from "react-bootstrap";
import i18n from "src/i18n";
import { Language } from "src/Languages";

export const Menu = () => {
  const navigate = useNavigate()
  const [selectedVersion, setSelectedVersion] = useState<AppRoutes>(AppRoutes.V3)
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(i18n.language as Language)

  const menuTitles = [
    { name: AppRoutes.about, value: t('menu.about') },
    { name: AppRoutes.services, value: t('menu.services') },
    { name: AppRoutes.library, value: t('menu.library') },
    { name: AppRoutes.society, value: t('menu.society') },
    { name: AppRoutes.programs, value: t('menu.programs') },
    { name: AppRoutes.nourishAndFlourishLetters, value: t('menu.NourishAndFlourishLetters') },
    { name: AppRoutes.contact, value: t('menu.contact') }
  ];

  const onRouteChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target) {
      setRoute(e.target.value as AppRoutes);
    }
  };

  function setRoute(value: AppRoutes) {
    setSelectedVersion(value);
    navigate(value);
  }

  const ChangeLanguage = (language: Language) => {
    changeLanguage(language);
    setSelectedLanguage(language)
  }

  return (<Navbar expand="xl" className="bg-creme menu-height">
    <div onClick={() => setRoute(AppRoutes.root)}><button className="btn btn-transparent border-0 rounded" ><img src={image} alt="" className="logo-max-width" /></button></div>
    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginLeft: 'auto', marginRight: '1rem' }} />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mx-auto bg-creme w-100">
        <div className="d-flex" >
          {/* {menuTitles.map((title, index) => {
            return (
              <Nav.Link onClick={() => setRoute(title.name)} key={`${title} ${index}`} className="nav-item active my-auto text-nowrap nav-menu-item-font" >
                <div className="px-2"> {title.value}</div>
              </Nav.Link>
            )
          })
          } */}
        </div>
        <div className="text-all-gold ms-auto my-auto">
          <button className={`btn border-0 ${selectedLanguage === Language.enGB ? 'text-decoration-underline' : ''}`} onClick={() => ChangeLanguage(Language.enGB)}>En</button>
          <button className={`btn border-0 ${selectedLanguage === Language.huHU ? 'text-decoration-underline' : ''}`} onClick={() => ChangeLanguage(Language.huHU)}>Hu</button>
        </div>
      </Nav>

    </Navbar.Collapse>

  </Navbar>)


};


