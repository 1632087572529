import { t } from 'i18next';
import './PathOfCreationSection.scss'
import { ReactComponent as LotusFlowerImg } from 'src/img/lotusFlower.svg';
import { MediumSlowFadeInWrapper } from 'src/animation/medium-slow-fade-in-wrapper';


export const PathOfCreationSection = () => {
    return <div className='d-flex text-white tapestry-bg px-5 font-playfair-display py-5 vh-100' style={{ minHeight: "900px" }}>
        <MediumSlowFadeInWrapper className='d-flex flex-column my-auto'>

            <div className="d-flex flex-row">
                <div className='d-grid flex-grow-1'>
                    <div className='image-circle' style={{ width: '30vw', maxWidth: '200px', maxHeight: '200px', borderRadius: '100%' }}>
                        <img className='path-of-creation-image' width={'100%'} src={"img/sunAndMoon.png"} alt="SunAndMoon" />
                    </div>

                </div>
                <div className='d-grid flex-grow-1'>
                    <div className='image-circle' style={{ width: '30vw', maxWidth: '200px', maxHeight: '200px', borderRadius: '100%' }}>
                        <img className='path-of-creation-image' width={'100%'} src={"img/moonCycle.png"} alt="SunAndMoon" />
                    </div>

                </div>
                <div className='d-grid flex-grow-1'>
                    <div className='image-circle px-2' style={{ width: '30vw', maxWidth: '200px', maxHeight: '200px', borderRadius: '100%' }}>
                        <LotusFlowerImg style={{ maxHeight: '300px' }} />
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row">
                <div className='d-flex flex-column py-3 w-100 text-center mx-2'>
                    <h4 className='fw-bold'> {t('home-page-path-of-creation-title-1')} </h4>
                </div>
                <div className='d-flex flex-column py-3 w-100 text-center mx-2'>
                    <h4 className='fw-bold'> {t('home-page-path-of-creation-title-2')} </h4>
                </div>
                <div className='d-flex flex-column py-3 w-100 text-center mx-2'>
                    <h4 className='fw-bold'> {t('home-page-path-of-creation-title-3')} </h4>
                </div>
            </div>

            <div className="d-flex flex-row mx-auto">
                <em className='d-flex flex-column text-brown text-justify mx-3'>
                    <div>{t('home-page-path-of-creation-textblock-1')}</div>
                </em>
                <em className='d-flex text-brown  flex-column text-justify mx-3'>
                    <div>{t('home-page-path-of-creation-textblock-2')}</div>
                </em>
                <em className='d-flex text-brown flex-column text-justify mx-3'>
                    <div>{t('home-page-path-of-creation-textblock-3')}</div>
                </em>
            </div>
        </MediumSlowFadeInWrapper>
    </div>
}


