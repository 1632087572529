import "./EllipseShapedImage.scss";

export const EllipseShapedImage = ({ image, className }: { image: string, className?: string }) => (
    <div className="d-flex mx-auto">
        <img
            className={`plektari-clip ${className ?? ''}`}
            src={image}
            alt=""
        />
    </div>
);