import backend, {HttpBackendOptions} from "i18next-http-backend";
import i18n, {InitOptions} from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

  i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init<InitOptions<HttpBackendOptions>>({
    lng: "hu-HU", // if you're using a language detector, do not define the lng option
    fallbackLng: "en-GB",
    supportedLngs: ['en-GB', 'hu-HU'],
    debug: process.env.REACT_APP_DebugTranslations === "true",
    backend:{
      loadPath: () => 'locales/{{lng}}.json',
      load: 'all',
    } as HttpBackendOptions,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  } as InitOptions);

  export default i18n