import { MediumSlowFadeInWrapper } from "src/animation/medium-slow-fade-in-wrapper"
import './WorkedWithSection.scss'
import { FeedBackRotator } from "./FeedBackRotator"

export const WorkedWithSection = () => {
    return <div className="d-flex text-white plant-shade-bg">
        <MediumSlowFadeInWrapper className='d-flex flex-column'>
            <FeedBackRotator />
        </MediumSlowFadeInWrapper>
    </div>
}


